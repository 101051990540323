import _ from 'lodash';

const EVENTS = {
  // Actions lancées quand l'utilisateur consulte les observations d'un relevé
  FIELD_SURVEY_FIELD_RECORDS_RP_LOADING: 'shu-field-record.field-survey-record-load-loading',
  FIELD_SURVEY_FIELD_RECORDS_RP_LOAD_SUCCESS: 'shu-field-record.field-survey-field-record-rp-load-success',
  FIELD_SURVEY_FIELD_RECORDS_RP_LOAD_ERROR: 'shu-field-record.field-survey-field-record-rp-load-error',
  FIELD_SURVEY_FIELD_RECORDS_RP_PROCESSING: 'shu-field-record.field-survey-field-record-rp-processing',
  FIELD_SURVEY_FIELD_RECORDS_RP_PROCESS_ERROR: 'shu-field-record.field-survey-field-record-rp-process-error',
};

export default FieldSurveyFieldRecordsRpService;

// @ngInject
function FieldSurveyFieldRecordsRpService($rootScope, FieldRecordRepository, LxNotificationService) {
  return {load, createFieldRecord, updateFieldRecord, removeFieldRecord, validateFieldRecord};

  function load(fieldSurveyId) {
    $rootScope.$emit(EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_LOADING);

    FieldRecordRepository.getForFieldSurveyRp(fieldSurveyId)
      .then((fieldRecordListResult) => {
        $rootScope.$emit(EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_LOAD_SUCCESS, fieldRecordListResult);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue lors de la récupération des informations des observations. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_LOAD_ERROR);
      });
  }

  function createFieldRecord(fieldSurveyId, studyId, fieldRecordForm, mapDataForm, fieldRecordExtraTableForms, formController) {
    $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_NEW_PROCESSING);

    let fieldRecordFormWithMap = _.defaults(fieldRecordForm, mapDataForm);

    FieldRecordRepository.create(studyId, fieldRecordFormWithMap, fieldRecordExtraTableForms)
      .then(() => FieldRecordRepository.getForFieldSurveyRp(fieldSurveyId))
      .then(
        (fieldRecordListResult) => {
          formController.$setPristine();
          LxNotificationService.success('Observation créée');
          $rootScope.$emit(EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_LOAD_SUCCESS, fieldRecordListResult);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue à la création de l\'observation. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_LOAD_ERROR);
          throw error;
        }
      );
  }

  function updateFieldRecord(fieldSurveyId, fieldRecordId, fieldRecordForm, mapDataForm, mediaForm, fieldRecordExtraTableForms, formController) {
    $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_PROCESSING);

    let fieldRecordFormWithMap = _.defaults(fieldRecordForm, mapDataForm);

    FieldRecordRepository.update(fieldRecordId, fieldRecordFormWithMap, mediaForm, fieldRecordExtraTableForms)
      .then(() => FieldRecordRepository.getForFieldSurveyRp(fieldSurveyId))
      .then(
        (fieldRecordListResult) => {
          formController.$setPristine();
          LxNotificationService.success('Observation mise à jour');
          $rootScope.$emit(EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_LOAD_SUCCESS, fieldRecordListResult);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue à la mise à jour de l\'observation. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_PROCESS_ERROR);
          throw error;
        }
      );
  }

  function validateFieldRecord(fieldSurveyId, fieldRecordId, formController) {
    $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_PROCESSING);

    FieldRecordRepository.validateFieldRecord(fieldRecordId, null)
      .then(() => FieldRecordRepository.getForFieldSurveyRp(fieldSurveyId))
      .then(
        (fieldRecordListResult) => {
          formController.$setPristine();
          LxNotificationService.success('Observation mise à jour');
          $rootScope.$emit(EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_LOAD_SUCCESS, fieldRecordListResult);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue à la mise à jour de l\'observation. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_PROCESS_ERROR);
          throw error;
        }
      );
  }

  function removeFieldRecord(fieldSurveyId, fieldRecordId) {
    $rootScope.$emit(EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_PROCESSING);

    FieldRecordRepository.deleteFieldRecord(fieldRecordId)
      .then(() => FieldRecordRepository.getForFieldSurveyRp(fieldSurveyId))
      .then((fieldRecordListResult) => {
        LxNotificationService.success('Observation supprimée');
        $rootScope.$emit(EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_LOAD_SUCCESS, fieldRecordListResult);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue lors de la suppression de l\'observation. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_PROCESS_ERROR);
      });
  }
}
export { EVENTS };
