import _ from 'lodash';

const EVENTS = {
  FIELD_RECORD_DETAILS_LOADING: 'shu-field-record.field-record-details-loading',
  FIELD_RECORD_DETAILS_LOAD_SUCCESS: 'shu-field-record.field-record-details-load-success',
  FIELD_RECORD_DETAILS_LOAD_ERROR: 'shu-field-record.field-record-details-load-error',
  FIELD_RECORD_DETAILS_PROCESSING: 'shu-field-record.field-record-details-processing',
  FIELD_RECORD_DETAILS_NEW_PROCESSING: 'shu-field-record.field-record-details-new-processing',
};

export default FieldRecordDetailsService;

// @ngInject
function FieldRecordDetailsService($rootScope, FieldRecordRepository, LxNotificationService, $state) {
  return { load, createFieldRecord, createFieldRecordOnTheFly, updateFieldRecord, deleteFieldRecord };

  function load(fieldRecordId) {
    $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOADING);

    FieldRecordRepository.getById(fieldRecordId, true).then(
      (fieldRecord) => {
        $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOAD_SUCCESS, fieldRecord);
        return fieldRecord;
      },
      (error) => {
        LxNotificationService.error(
          "Une erreur est survenue lors de la récupération de l'observation. " + (error.data || '')
        );
        $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOAD_ERROR);
        throw error;
      }
    );
  }

  function createFieldRecord(
    studyId,
    fieldRecordForm,
    mapDataForm,
    fieldRecordExtraTableForms,
    fromState,
    fromParams,
    continueCreation,
    formController
  ) {
    $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_NEW_PROCESSING);

    let fieldRecordFormWithMap = _.defaults(fieldRecordForm, mapDataForm);
    FieldRecordRepository.create(studyId, fieldRecordFormWithMap, fieldRecordExtraTableForms).then(
      (fieldRecord) => {
        formController.$setPristine();
        LxNotificationService.success('Observation créée');
        $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOAD_SUCCESS, fieldRecord);
        if (continueCreation) {
          let params = {
            studyId,
            fieldSurveyId: fieldRecordForm.fieldSurveyId,
            txRefKey: fieldRecordForm.txRefType,
            txRefFilter: fieldRecordForm.txRefFilter,
            txKey: undefined,
            txGroup: fieldRecordForm.txGroupKey,
            fromState,
            fromParams: fromParams && JSON.stringify(fromParams),
          };
          $state.go('field-record-creation', params, { reload: true });
          return;
        }

        if (fromState === 'field-survey-details' && fromParams && fromParams.openMapTab) {
          // Cas spécifique : la saisie depuis la carte d'un relevé est plus prioritaire que
          // la règle "fieldRecord.fieldSurvey" (saisie dans un relevé) pour la redirection
          $state.go(fromState, fromParams);
          return;
        }

        // Saisie d'une observation dans un relevé, on va vers la liste des observations du relevé
        if (fieldRecord.fieldSurvey) {
          $state.go('field-survey-field-records', { studyId, fieldSurveyId: fieldRecord.fieldSurvey.id });
          return;
        }

        if (!fromState) {
          $state.go('field-record-details', { studyId, fieldRecordId: fieldRecord.id });
          return;
        }

        $state.go(fromState, fromParams);
      },
      (error) => {
        LxNotificationService.error("Une erreur est survenue à la création de l'observation. " + (error.data || ''));
        $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOAD_ERROR);
        throw error;
      }
    );
  }

  function createFieldRecordOnTheFly(studyId, fieldRecordForm, mapDataForm, extraTablesForm, formController) {
    $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_NEW_PROCESSING);

    let fieldRecordFormWithMap = _.defaults(fieldRecordForm, mapDataForm);

    FieldRecordRepository.create(studyId, fieldRecordFormWithMap, extraTablesForm).then(
      (fieldRecord) => {
        formController.$setPristine();
        LxNotificationService.success('Observation créée');
        $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOAD_SUCCESS, fieldRecord);
      },
      (error) => {
        LxNotificationService.error("Une erreur est survenue à la création de l'observation. " + (error.data || ''));
        $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOAD_ERROR);
        throw error;
      }
    );
  }

  function updateFieldRecord(
    fieldRecordId,
    fieldRecordForm,
    mapDataForm,
    mediaForm,
    fieldRecordExtraTableForms,
    formController,
    noEmit
  ) {
    if (!noEmit) {
      $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_PROCESSING);
    }

    let fieldRecordFormWithMap = _.defaults(fieldRecordForm, mapDataForm);

    FieldRecordRepository.update(fieldRecordId, fieldRecordFormWithMap, mediaForm, fieldRecordExtraTableForms).then(
      (fieldRecord) => {
        formController.$setPristine();
        LxNotificationService.success('Observation mise à jour');
        if (!noEmit) {
          $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOAD_SUCCESS, fieldRecord);
        }
      },
      (error) => {
        LxNotificationService.error("Une erreur est survenue à la mise à jour de l'observation. " + (error.data || ''));
        $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOAD_ERROR);
        throw error;
      }
    );
  }

  function deleteFieldRecord(fieldRecordId) {
    $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_PROCESSING);

    FieldRecordRepository.deleteFieldRecord(fieldRecordId).then(
      (fieldRecord) => {
        LxNotificationService.success('Observation supprimée');
        $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOAD_SUCCESS, null);
      },
      (error) => {
        LxNotificationService.error("Une erreur est survenue à la suppression de l'observation. " + (error.data || ''));
        $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOAD_ERROR);
        throw error;
      }
    );
  }
}
export { EVENTS };
