import _ from 'lodash';
import { EXTRA_TABLE as FS_EXTRA_TABLE } from '../../field-survey/model';
import { EXTRA_TABLE as FR_EXTRA_TABLE } from '../../field-record/model';
import { STUDY_EXPERT_LAYER_SOURCE, STUDY_EXPERT_LAYER_FORMAT } from '../../study/model';
import fieldSurveyExtraTableUtil from '../../field-survey/tools/extra-table-util';
import fieldRecordExtraTableUtil from '../../field-record/tools/extra-table-util';

const EVENTS = {
  AUTO_SYNC_STATUS: 'shu.auto-sync-status',
};

export { EVENTS };

export default SynchroRepository;

// @ngInject
function SynchroRepository(
  SynchroConfiguration,
  $http,
  $q,
  TaxonRefRepository,
  DatabaseProvider,
  StorageService,
  UrlResolverService,
  $cordovaFileTransfer,
  $cordovaFile,
  AuthStore,
  FieldSurveyRepository,
  FieldRecordRepository,
  MediaRepository,
  StudyConfiguration,
  StudyExpertLayerRepository,
  Geolocation,
  SettingsOppService,
  StudyRepository,
  $rootScope
) {
  let autoSyncTimeout = null;

  return {
    searchImportableStudies,
    getStudyTxRefs,
    //getLastVersionTxRefs,
    //getLastVersionTxRef,
    getStudy,
    getProtocols,
    getProtocolTxGroups,
    getSurveyTypes,
    getSurveyTypeProtocolTxGroups,
    getFieldSurveys,
    getFieldSurveyExtraTables,
    getFieldRecords,
    getFieldRecordExtraTables,
    getBiotopeRefs,
    getMbTiles,
    getStudyExpertLayer,
    getTerrUnit,
    getTxRef,
    storeStudyData,
    storeSurveyTypeData,
    storeFieldSurveyData,
    storeFieldRecordData,
    storeBiotopeRefs,
    lockStudy,
    unlockStudy,
    sendMedia,
    sendStudyData,
    removeStudyData,
    removeStudyMap,
    removeLocalTxRef,
    updateStudyLockInfo,
    replaceStudyExpertLayers,
    updateStudyImagerySets,
    getLocalTxRefs,
    saveAutoSync,
    getAutoSync,
    stopAutoSync,
    startAutoSync,
    setAutoSyncStudyId,
    setAutoSyncEnabled,
    //getOutdatedTxRefs,
  };

  function saveAutoSync(value) {
    return new Promise((resolve, reject) => {
      SettingsOppService.setShuSettings('autoSync', JSON.stringify(value)).then(() => {
        resolve();
      });
    });
  }

  function getAutoSync() {
    return new Promise((resolve) => {
      SettingsOppService.getShuSettings(
        'autoSync',
        JSON.stringify({
          interval: 1000 * 60 * 1,
          isEnabled: true,
          studyId: null,
        })
      ).then((autoSync) => {
        resolve(JSON.parse(autoSync));
      });
    });
  }

  function setAutoSyncStudyId(id) {
    return new Promise((resolve) => {
      getAutoSync().then((autoSync) => {
        autoSync.studyId = id;
        saveAutoSync(autoSync).then((res) => {
          if (!id) {
            stopAutoSync().then(() => {
              resolve();
            });
          } else {
            startAutoSync().then(
              () => {
                resolve();
              },
              () => {
                resolve();
              }
            );
          }
        });
      });
    });
  }

  function setAutoSyncEnabled(value) {
    return new Promise((resolve) => {
      getAutoSync().then((autoSync) => {
        if (autoSync.isEnabled == value) {
          return resolve();
        }
        autoSync.isEnabled = value;
        saveAutoSync(autoSync).then((res) => {
          if (!value) {
            stopAutoSync().then(() => {
              resolve();
            });
          } else {
            startAutoSync(true).then(
              () => {
                resolve();
              },
              () => {
                resolve();
              }
            );
          }
        });
      });
    });
  }

  function stopAutoSync() {
    return new Promise((resolve) => {
      getAutoSync().then((autoSync) => {
        if (autoSyncTimeout) {
          clearTimeout(autoSyncTimeout);
          autoSyncTimeout = null;
        }
        saveAutoSync(autoSync).then((res) => {
          $rootScope.$broadcast(EVENTS.AUTO_SYNC_STATUS, 'stopped');
          resolve();
        });
      });
    });
  }

  function startAutoSync(forceEnabled) {
    return new Promise((resolve, reject) => {
      getAutoSync().then((autoSync) => {
        if (!autoSync.studyId || (!autoSync.isEnabled && !forceEnabled)) {
          return reject();
        }
        autoSync.isEnabled = true;
        saveAutoSync(autoSync).then((res) => {
          if (!autoSyncTimeout) {
            processAutoSync();
          }
          $rootScope.$broadcast(EVENTS.AUTO_SYNC_STATUS, 'running');
          resolve(autoSync);
        });
      });
    });
  }

  function processAutoSync() {
    if (autoSyncTimeout) {
      clearTimeout(autoSyncTimeout);
      autoSyncTimeout = null;
    }
    getAutoSync().then((autoSync) => {
      if (!autoSync.studyId || !autoSync.isEnabled || !autoSync.interval) {
        return;
      }
      autoSyncTimeout = setTimeout(() => {
        sendAutoSync().then(() => {
          processAutoSync();
        });
      }, autoSync.interval);
    });
  }

  function sendAutoSync() {
    return new Promise((resolve) => {
      getAutoSync().then((autoSync) => {
        if (!autoSync.studyId) {
          return resolve();
        }
        StudyRepository.getStudy(autoSync.studyId).then((study) => {
          $q.all([loadFieldSurvey(study), loadFieldRecordOnTheFly(study)]).then(
            ([fieldSurveyWithFieldRecords, fieldRecordOnTheFly]) => {
              if (
                !fieldSurveyWithFieldRecords.fieldSurveys.length &&
                !fieldRecordOnTheFly.fieldRecordsOnTheFly.length
              ) {
                $rootScope.$broadcast('AUTO_SYNC_TERMINATED');
                return resolve();
              }
              doSendStudyData(autoSync.studyId, fieldSurveyWithFieldRecords, fieldRecordOnTheFly).then(
                (response) => {
                  updateStudyLockInfo(autoSync.studyId, response.latestSyncTime).then(
                    (success) => {
                      $rootScope.$broadcast('AUTO_SYNC_TERMINATED');
                      resolve();
                    },
                    (error) => {
                      console.log('updateStudyLockInfo error', error);
                      resolve();
                    }
                  );
                },
                (err) => {
                  console.log('doSendStudyData error', err);
                  resolve();
                }
              );
            }
          );
        });
      });
    });
  }

  function searchImportableStudies() {
    return $http({
      method: 'GET',
      url: SynchroConfiguration.searchImportableEndpoint,
    }).then((response) => response.data);
  }

  function getStudyTxRefs(studyIds) {
    return $http({
      method: 'POST',
      url: SynchroConfiguration.studyTxRefsEndpoint,
      data: studyIds,
    }).then((response) => response.data);
  }

  /*function getLastVersionTxRefs() {
    return $http({
      method: 'GET',
      url: SynchroConfiguration.lastVersionTxRefsEndpoint,
      params: { target: 'tablet' },
    }).then((response) => response.data);
  }*/

  /*function getLastVersionTxRef(key) {
    return $http({
      method: 'GET',
      url: SynchroConfiguration.lastVersionTxRefEndpoint,
      pathParams: { key },
    }).then((response) => response.data);
  }*/

  /*function getOutdatedTxRefs() {
    return $q((resolve, reject) => {
      if (!IS_CORDOVA) {
        return reject();
      }

      let storedOutdateds = [];
      try {
        storedOutdateds = JSON.parse(localStorage.getItem('outdatedTxRefs')) || [];
      } catch (error) {}

      const hasNetwork = _.get(navigator, 'connection.type') != Connection.NONE;
      if (!hasNetwork) {
        return resolve(storedOutdateds);
      }

      TaxonRefRepository.getAllTxRefFilters().then((localTxRefs) => {
        getLastVersionTxRefs().then(
          (lastTxRefs) => {
            const outdateds = localTxRefs
              .filter((localTxRef) => {
                const lastTxRef = lastTxRefs.find((lastTxRef) => {
                  return lastTxRef.key == localTxRef.key;
                });
                return lastTxRef && lastTxRef.updated > localTxRef.updated;
              })
              .map((outdated) => outdated.key);
            localStorage.setItem('outdatedTxRefs', JSON.stringify(outdateds));
            resolve(outdateds);
          },
          (error) => {
            console.log('getsOutdatedTxRefs error', error);
            return resolve(storedOutdateds);
          }
        );
      });
    });
  }*/

  function getStudy(studyId) {
    return $http({
      method: 'GET',
      url: SynchroConfiguration.importStudyEndpoint,
      pathParams: { studyId },
    }).then((response) => response.data);
  }

  function getProtocols() {
    return $http({
      method: 'GET',
      url: SynchroConfiguration.importProtocolsEndpoint,
    }).then((response) => response.data);
  }

  function getProtocolTxGroups() {
    return $http({
      method: 'GET',
      url: SynchroConfiguration.importProtocolTxGroupsEndpoint,
    }).then((response) => response.data);
  }

  function getSurveyTypes(studyId) {
    return $http({
      method: 'GET',
      url: SynchroConfiguration.importSurveyTypesEndpoint,
      pathParams: { studyId },
    }).then((response) => response.data);
  }

  function getSurveyTypeProtocolTxGroups(studyId) {
    return $http({
      method: 'GET',
      url: SynchroConfiguration.importSurveyTypeProtocolTxGroupsEndpoint,
      pathParams: { studyId },
    }).then((response) => response.data);
  }

  function getFieldSurveys(studyId) {
    return $http({
      method: 'GET',
      url: SynchroConfiguration.importFieldSurveysEndpoint,
      pathParams: { studyId },
    }).then((response) => response.data);
  }

  function getFieldSurveyExtraTables(studyId) {
    return $http({
      method: 'GET',
      url: SynchroConfiguration.importFieldSurveyExtraTablesEndpoint,
      pathParams: { studyId },
    }).then((response) => response.data);
  }

  function getFieldRecords(studyId) {
    return $http({
      method: 'GET',
      url: SynchroConfiguration.importFieldRecordsEndpoint,
      pathParams: { studyId },
    }).then((response) => response.data);
  }

  function getFieldRecordExtraTables(studyId) {
    return $http({
      method: 'GET',
      url: SynchroConfiguration.importFieldRecordExtraTablesEndpoint,
      pathParams: { studyId },
    }).then((response) => response.data);
  }

  function getBiotopeRefs() {
    return $http({
      method: 'GET',
      url: SynchroConfiguration.getBiotopeRefs,
    }).then((response) => response.data);
  }

  function lockStudy(studyId) {
    return $http({
      method: 'POST',
      url: SynchroConfiguration.lockStudy,
      pathParams: { studyId },
    }).then((response) => response.data);
  }

  function unlockStudy(studyId) {
    return $http({
      method: 'POST',
      url: SynchroConfiguration.unlockStudy,
      pathParams: { studyId },
    }).then((response) => response.data);
  }

  function getMbTiles(studyId, imagerySet) {
    return $q((resolve, reject) => {
      document.addEventListener(
        'deviceready',
        function() {
          UrlResolverService.resolveUrl(SynchroConfiguration.importMbTilesEndpoint, { imagerySet, studyId }).then(
            (url) => {
              var targetPath = `${cordova.file.applicationStorageDirectory}databases/${studyId}_${imagerySet}.mbtiles`;

              $cordovaFileTransfer
                .download(
                  url,
                  targetPath,
                  {
                    headers: { Authorization: 'Bearer ' + AuthStore.getState().token },
                  },
                  false
                )
                .then(
                  (result) => resolve(result),
                  (err) => reject(err)
                );
            }
          );
        },
        false
      );
    });
  }

  function replaceStudyTerrUnit(database, results, studyId) {
    return StorageService.executeSqlQuery(database, 'DELETE FROM terr_unit WHERE study = ?', [studyId]).then(() =>
      StorageService.executeSqlQueries(
        database,
        _.map(results.data, (terrUnit) => {
          return {
            sql: 'INSERT OR REPLACE INTO terr_unit VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
            parameters: [
              studyId,
              terrUnit.id,
              terrUnit.key,
              terrUnit.name,
              terrUnit.level,
              terrUnit.boundaryType,
              terrUnit.created,
              terrUnit.createdBy,
              terrUnit.updated,
              terrUnit.updatedBy,
              terrUnit.deleted,
              terrUnit.deletedBy,
            ],
          };
        })
      )
    );
  }

  function removeRemoteLayersFiles(studyId) {
    return $q((resolve, reject) => {
      document.addEventListener(
        'deviceready',
        () =>
          $cordovaFile.checkDir(cordova.file.applicationStorageDirectory, `layers/${studyId}`).then(
            () =>
              $cordovaFile.removeRecursively(cordova.file.applicationStorageDirectory, `layers/${studyId}`).then(
                (data) => resolve(data),
                (err) => reject(err)
              ),
            () => {
              // Le répertoire n'existe pas
              resolve();
            }
          ),
        false
      );
    });
  }

  function removeRemoteLayers(database, studyId) {
    return StorageService.executeSqlQuery(database, `DELETE FROM study_expert_layer WHERE study = ? AND source = ?`, [
      studyId,
      STUDY_EXPERT_LAYER_SOURCE.SERVER,
    ]).then(() => removeRemoteLayersFiles(studyId));
  }

  function removeAllLayers(database, studyId) {
    return removeRemoteLayers(database, studyId).then(() => StudyExpertLayerRepository.removeLayers(database, studyId));
  }

  function getStudyExpertLayer(studyId, studyExpertLayerId) {
    return $q((resolve, reject) => {
      document.addEventListener(
        'deviceready',
        function() {
          UrlResolverService.resolveUrl(StudyConfiguration.studyExpertLayerDetailEndpoint, {
            studyId,
            studyExpertLayerId,
          }).then((url) => {
            var layerId = StorageService.prepareId(studyExpertLayerId);
            var targetPath = `${cordova.file.applicationStorageDirectory}layers/${studyId}/${layerId}`;

            $cordovaFileTransfer
              .download(
                url,
                targetPath,
                {
                  headers: { Authorization: 'Bearer ' + AuthStore.getState().token },
                },
                false
              )
              .then(
                () => resolve(targetPath),
                (err) => reject(err)
              );
          });
        },
        false
      );
    });
  }

  function getRemoteForStudy(studyId) {
    return $http({
      method: 'GET',
      url: StudyConfiguration.studyExpertLayerEndpoint,
      pathParams: { studyId },
    }).then((response) => response.data);
  }

  function replaceStudyExpertLayers(studyId) {
    return DatabaseProvider.getDatabase().then((database) =>
      removeRemoteLayers(database, studyId)
        .then(() => getRemoteForStudy(studyId))
        .then((layers) =>
          _.reduce(
            layers,
            (previousPromise, layer) => {
              return previousPromise.then(() => {
                return getStudyExpertLayer(studyId, layer.id).then((path) => {
                  return StorageService.executeSqlQuery(
                    database,
                    'INSERT OR REPLACE INTO study_expert_layer VALUES (?, ?, ?, ?, ?, ?, ?)',
                    [
                      StorageService.prepareId(layer.id),
                      studyId,
                      layer.name,
                      layer.created,
                      STUDY_EXPERT_LAYER_SOURCE.SERVER,
                      STUDY_EXPERT_LAYER_FORMAT.KML,
                      path,
                    ]
                  );
                });
              });
            },
            $q.when(true)
          )
        )
    );
  }

  function getTerrUnit(studyId) {
    return $http({
      method: 'GET',
      url: SynchroConfiguration.importTerrUnitEndpoint,
      pathParams: { studyId },
      params: { noGeom: true },
    }).then((results) => {
      return DatabaseProvider.getDatabase().then((database) => replaceStudyTerrUnit(database, results, studyId));
    });
  }

  function getLocalTxRefs() {
    return $q((resolve, reject) => {
      document.addEventListener(
        'deviceready',
        function() {
          TaxonRefRepository.getAllTypes().then(
            (txRefTypes) => {
              TaxonRefRepository.getAllTxRefFilters().then(
                (txRefFilter) => {
                  var result = txRefFilter.concat(txRefTypes);
                  resolve(result);
                },
                (err) => reject(err)
              ); //resolve(txRefTypes)
            },
            (err) => reject(err)
          );
        },
        false
      );
    });
  }



  function getTxRef(txRefFilter) {
    return $q((resolve, reject) => {
      document.addEventListener(
        'deviceready',
        function() {
          //we insert a "fake" txreftype to force present a base ref like TXREF
          DatabaseProvider.getDatabase().then((database) => {
            StorageService.executeSqlQueries(database, [
              {
                sql: 'INSERT OR REPLACE INTO tx_ref_import (key, version, name, updated, ref, imported) VALUES (?, ?, ?, ?, ?, ?)',
                parameters: [
                  txRefFilter.ref,
                  txRefFilter.version,
                  txRefFilter.ref + ' ' + txRefFilter.version,
                  txRefFilter.updated,
                  txRefFilter.ref,
                  new Date().getTime() / 1000,
                ],
              },
            ]).then(() => {
              UrlResolverService.resolveUrl(SynchroConfiguration.importTxRefEndpoint, {
                txRefType: txRefFilter.ref,
                txRefVersion: txRefFilter.version,
                txRefFilter: txRefFilter.key,
              }).then((url) => {
                var refname = txRefFilter.key;
                var targetPath = `${cordova.file.applicationStorageDirectory}databases/${refname}.db`;
                $cordovaFileTransfer
                  .download(
                    url,
                    targetPath,
                    {
                      headers: { Authorization: 'Bearer ' + AuthStore.getState().token },
                    },
                    false
                  )
                  .then(
                    (result) => {
                      DatabaseProvider.getDatabase().then(
                        (database) => {
                          return StorageService.executeSqlQueries(database, [
                            {
                              sql: 'INSERT OR REPLACE INTO tx_ref_import (key, version, name, updated, ref, imported) VALUES (?, ?, ?, ?, ?, ?)',
                              parameters: [
                                txRefFilter.key,
                                txRefFilter.version,
                                txRefFilter.name,
                                txRefFilter.updated,
                                txRefFilter.ref,
                                new Date().getTime() / 1000,
                              ],
                            },
                          ]);
                        },
                        (err) => {
                          console.log('update tx_ref_import error', err);
                          reject(err);
                        }
                      );
                    },
                    (err) => {
                      console.log('cordovaFileTransfer error', err);
                      reject(err);
                    }
                  )
                  .then(
                    (result) => {
                      resolve(result);
                    },
                    (err) => {
                      console.log('err', err);
                      reject(err);
                    }
                  );
              });
            });
          });
        },
        false
      );
    });
  }

  function replaceStudy(database, study) {
    return StorageService.executeSqlQuery(
      database,
      'INSERT OR REPLACE INTO study VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
      [
        study.id,
        study.key,
        study.name,
        study.nameUnaccent,
        study.isBiblio,
        study.biblioSrc,
        study.startTime,
        study.endTime,
        study.timezone,
        study.studyBoundary,
        study.applicationKey,
        study.accessConstraint,
        study.useConstraint,
        study.status,
        study.created,
        study.createdBy,
        study.updated,
        study.updatedBy,
        study.deleted,
        study.deletedBy,
        StorageService.prepareArrayData(study.imagerySets),
      ]
    );
  }
  function updateStudyImagerySets(studyId, imagerySets) {
    DatabaseProvider.getDatabase().then((database) => {
      return StorageService.executeSqlQuery(database, 'UPDATE study SET imagery_sets = ? WHERE id = ?', [
        StorageService.prepareArrayData(imagerySets),
        studyId,
      ]);
    });
  }
  function replaceStudyLockInfo(database, study) {
    return StorageService.executeSqlQuery(database, 'INSERT OR REPLACE INTO study_lock_info VALUES (?, ?, ?)', [
      study.id,
      study.lockTime,
      study.latestSyncTime,
    ]);
  }
  function replaceStudyExpert(database, study) {
    return StorageService.executeSqlQueries(
      database,
      _.map(study.roles, (role) => {
        return {
          sql: 'INSERT OR REPLACE INTO study_expert VALUES (?, ?)',
          parameters: [study.id, role],
        };
      })
    );
  }
  function replaceProtol(database, protocols) {
    return StorageService.executeSqlQueries(
      database,
      _.map(protocols, (protocol) => {
        return {
          sql: 'INSERT INTO protocol VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
          parameters: [
            protocol.id,
            protocol.key,
            protocol.name,
            protocol.description,
            StorageService.prepareArrayData(protocol.txGroups),
            protocol.fieldSurveyGeometryType,
            protocol.created,
            protocol.createdBy,
            protocol.updated,
            protocol.updatedBy,
            protocol.deleted,
            protocol.deletedBy,
          ],
        };
      })
    );
  }
  function replaceProtocolTxGroup(database, protocolTxGroups) {
    return StorageService.executeSqlQueries(
      database,
      _.map(protocolTxGroups, (protocolTxGroup) => {
        return {
          sql: 'INSERT INTO protocol_tx_group VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
          parameters: [
            protocolTxGroup.id,
            protocolTxGroup.name,
            protocolTxGroup.txGroup,
            protocolTxGroup.protocol,
            StorageService.prepareArrayData(protocolTxGroup.fieldSurveyExtraTables),
            StorageService.prepareArrayData(protocolTxGroup.fieldRecordExtraTables),
            protocolTxGroup.created,
            protocolTxGroup.createdBy,
            protocolTxGroup.updated,
            protocolTxGroup.updatedBy,
            protocolTxGroup.deleted,
            protocolTxGroup.deletedBy,
          ],
        };
      })
    );
  }

  function storeStudyData(study, protocols, protocolTxGroups) {
    return DatabaseProvider.getDatabase().then((database) => {
      return StorageService.executeSqlQuery(database, 'DELETE FROM protocol')
        .then(() => StorageService.executeSqlQuery(database, 'DELETE FROM protocol_tx_group'))
        .then(() => replaceStudy(database, study))
        .then(() => replaceStudyLockInfo(database, study))
        .then(() => replaceStudyExpert(database, study))
        .then(() => replaceProtol(database, protocols))
        .then(() => replaceProtocolTxGroup(database, protocolTxGroups));
    });
  }

  function deleteSurveyType(database, studyId) {
    return StorageService.executeSqlQuery(database, 'DELETE FROM survey_type WHERE study = ?', [studyId]);
  }
  function deleteSurveyTypeProtocolTxGroup(database, studyId) {
    return StorageService.executeSqlQuery(
      database,
      `DELETE FROM survey_type_protocol_tx_group WHERE survey_type IN (SELECT st.id FROM survey_type st WHERE st.study = ?)`,
      [studyId]
    );
  }
  function replaceSurveyType(database, studyId, surveyTypes) {
    return StorageService.executeSqlQueries(
      database,
      _.map(surveyTypes, (surveyType) => {
        return {
          sql: 'INSERT INTO survey_type VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
          parameters: [
            surveyType.id,
            surveyType.key,
            surveyType.name,
            surveyType.defaultTxRefType,
            studyId,
            surveyType.protocol,
            surveyType.created,
            surveyType.createdBy,
            surveyType.updated,
            surveyType.updatedBy,
            surveyType.deleted,
            surveyType.deletedBy,
            surveyType.defaultTxRefFilter,
          ],
        };
      })
    );
  }
  function replaceSurveyTypeProtocolTxGroup(database, surveyTypeProtocolTxGroups) {
    return StorageService.executeSqlQueries(
      database,
      _.map(surveyTypeProtocolTxGroups, (surveyTypeProtocolTxGroup) => {
        return {
          sql: 'INSERT INTO survey_type_protocol_tx_group VALUES (?, ?)',
          parameters: [surveyTypeProtocolTxGroup.surveyType, surveyTypeProtocolTxGroup.protocolTxGroup],
        };
      })
    );
  }

  function storeSurveyTypeData(studyId, surveyTypes, surveyTypeProtocolTxGroups) {
    return DatabaseProvider.getDatabase().then((database) => {
      return deleteSurveyTypeProtocolTxGroup(database, studyId)
        .then(() => deleteSurveyType(database, studyId))
        .then(() => replaceSurveyType(database, studyId, surveyTypes))
        .then(() => replaceSurveyTypeProtocolTxGroup(database, surveyTypeProtocolTxGroups));
    });
  }

  function deleteFieldSurveyMedia(database, studyId) {
    return StorageService.executeSqlQuery(
      database,
      `DELETE FROM media
       WHERE id IN (
          SELECT fsm.media
          FROM field_survey_media fsm
          JOIN field_survey fs ON (fs.id = fsm.field_survey)
          JOIN survey_type st ON (st.id = fs.survey_type AND st.study = ?)
        )`,
      [studyId]
    ).then(() =>
      StorageService.executeSqlQuery(
        database,
        `DELETE FROM field_survey_media
         WHERE field_survey IN (
          SELECT fs.id
          FROM field_survey fs
          JOIN survey_type st ON (st.id = fs.survey_type AND st.study = ?)
        )`,
        [studyId]
      )
    );
  }
  function deleteFieldSurveyExtraTable(database, extraTables, studyId) {
    return _.reduce(
      extraTables,
      (previousPromise, extraTable) => {
        return previousPromise.then(() => {
          return StorageService.executeSqlQuery(
            database,
            `DELETE FROM ${extraTable}
             WHERE id IN (
              SELECT fs.id
              FROM field_survey fs
              JOIN survey_type st ON (st.id = fs.survey_type AND st.study = ?)
          )`,
            [studyId]
          );
        });
      },
      $q.when(true)
    );
  }
  function deleteFieldSurvey(database, studyId) {
    return StorageService.executeSqlQuery(
      database,
      `DELETE FROM field_survey
       WHERE survey_type IN (
        SELECT st.id
        FROM survey_type st
        WHERE st.study = ?
      )
    `,
      [studyId]
    );
  }
  function replaceFieldSurvey(database, fieldSurveyId, fieldSurvey) {
    return StorageService.executeSqlQuery(
      database,
      'INSERT INTO field_survey VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
      [
        fieldSurveyId,
        fieldSurvey.key,
        fieldSurvey.name,
        fieldSurvey.description,
        fieldSurvey.terrUnit,
        fieldSurvey.terrUnitName,
        fieldSurvey.place,
        StorageService.prepareGeoJsonData(fieldSurvey.geometry),
        fieldSurvey.expert,
        fieldSurvey.startTime,
        fieldSurvey.endTime,
        fieldSurvey.surveyType,
        fieldSurvey.created,
        fieldSurvey.createdBy,
        fieldSurvey.updated,
        fieldSurvey.updatedBy,
        fieldSurvey.deleted,
        fieldSurvey.deletedBy,
      ]
    );
  }
  function replaceFieldSurveyMedia(database, fieldSurveyId, fieldSurvey) {
    return $q.all(
      _.map(fieldSurvey.media, (media) => {
        var mediaId = StorageService.prepareId(media.id);
        return StorageService.executeSqlQuery(database, 'INSERT OR REPLACE INTO media VALUES (?, ?, ?, ?, ?, ?)', [
          mediaId,
          media.mimeType,
          media.filename,
          media.created,
          media.updated,
          media.deleted,
        ]).then(() => {
          return StorageService.executeSqlQuery(database, 'INSERT OR REPLACE INTO field_survey_media VALUES (?, ?)', [
            fieldSurveyId,
            mediaId,
          ]);
        });
      })
    );
  }
  function replaceFieldSurveysWithMedia(database, fieldSurveys) {
    return _.reduce(
      fieldSurveys,
      (previousPromise, fieldSurvey) => {
        var fieldSurveyId = StorageService.prepareId(fieldSurvey.id);
        return previousPromise.then(() => {
          return replaceFieldSurvey(database, fieldSurveyId, fieldSurvey).then(() =>
            replaceFieldSurveyMedia(database, fieldSurveyId, fieldSurvey)
          );
        });
      },
      $q.when(true)
    );
  }
  function replaceExtraTables(database, extraTables) {
    return _.reduce(
      _.toPairs(extraTables),
      (previousPromise, [tableName, dataList]) => {
        return previousPromise.then(() => {
          return StorageService.executeSqlQueries(
            database,
            StorageService.generateInsertQueries(tableName, dataList, true)
          );
        });
      },
      $q.when(true)
    );
  }

  function storeFieldSurveyData(studyId, fieldSurveys, extraTables) {
    return DatabaseProvider.getDatabase().then((database) => {
      return deleteFieldSurveyMedia(database, studyId)
        .then(() => deleteFieldSurveyExtraTable(database, _.keys(extraTables), studyId))
        .then(() => deleteFieldSurvey(database, studyId))
        .then(() => replaceFieldSurveysWithMedia(database, fieldSurveys))
        .then(() => replaceExtraTables(database, extraTables));
    });
  }

  function deleteFieldRecordMedia(database, studyId) {
    return StorageService.executeSqlQuery(
      database,
      `DELETE FROM media
       WHERE id IN (
        SELECT frm.media
        FROM field_record_media frm
        JOIN field_record fr ON (fr.id = frm.field_record AND fr.study = ?)
      )`,
      [studyId]
    ).then(() =>
      StorageService.executeSqlQuery(
        database,
        'DELETE FROM field_record_media WHERE field_record IN (SELECT id FROM field_record fr WHERE study = ?)',
        [studyId]
      )
    );
  }
  function deleteFieldRecordExtraTable(database, extraTables, studyId) {
    return _.reduce(
      extraTables,
      (previousPromise, extraTable) => {
        return previousPromise.then(() => {
          return StorageService.executeSqlQuery(
            database,
            `DELETE FROM ${extraTable} WHERE id IN (SELECT fr.id FROM field_record fr WHERE study = ?)`,
            [studyId]
          );
        });
      },
      $q.when(true)
    );
  }
  function deleteFieldRecord(database, studyId) {
    return StorageService.executeSqlQuery(database, 'DELETE FROM field_record WHERE study = ?', [studyId]);
  }
  function replaceFieldRecord(database, studyId, fieldRecordId, fieldRecord) {
    var txRefFilter = 'france';
    if (fieldRecord.txRefHistory && fieldRecord.txRefHistory.tx_ref_filter) {
      txRefFilter = fieldRecord.txRefHistory.tx_ref_filter;
    }

    return StorageService.executeSqlQuery(
      database,
      'INSERT INTO field_record VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
      [
        fieldRecordId,
        fieldRecord.key,
        fieldRecord.description,
        studyId,
        StorageService.prepareId(fieldRecord.fieldSurvey),
        fieldRecord.expert,
        StorageService.prepareGeoJsonData(fieldRecord.expertPosition),
        fieldRecord.expertPositionAccuracy,
        fieldRecord.txPosition,
        fieldRecord.txRefType,
        fieldRecord.txRefVersion,
        fieldRecord.txKey,
        fieldRecord.txName,
        fieldRecord.txVernacularName,
        fieldRecord.isTxSure,
        fieldRecord.isTxPresent,
        fieldRecord.txHeadcount,
        fieldRecord.txHeadcountAccuracy,
        fieldRecord.txTime,
        fieldRecord.txGroup,
        fieldRecord.status,
        fieldRecord.created,
        fieldRecord.createdBy,
        fieldRecord.updated,
        fieldRecord.updatedBy,
        fieldRecord.deleted,
        fieldRecord.deletedBy,
        fieldRecord.ghost,
        txRefFilter,
      ]
    );
  }
  function replaceFieldRecordMedia(database, fieldRecordId, fieldRecord) {
    return $q.all(
      _.map(fieldRecord.media, (media) => {
        var mediaId = StorageService.prepareId(media.id);
        return StorageService.executeSqlQuery(database, 'INSERT OR REPLACE INTO media VALUES (?, ?, ?, ?, ?, ?)', [
          mediaId,
          media.mimeType,
          media.filename,
          media.created,
          media.updated,
          media.deleted,
        ]).then(() => {
          return StorageService.executeSqlQuery(database, 'INSERT OR REPLACE INTO field_record_media VALUES (?, ?)', [
            fieldRecordId,
            mediaId,
          ]);
        });
      })
    );
  }
  function replaceFieldRecordsWithMedia(database, studyId, fieldRecords) {
    return _.reduce(
      fieldRecords,
      (previousPromise, fieldRecord) => {
        var fieldRecordId = StorageService.prepareId(fieldRecord.id);
        return previousPromise.then(() => {
          return replaceFieldRecord(database, studyId, fieldRecordId, fieldRecord).then(() =>
            replaceFieldRecordMedia(database, fieldRecordId, fieldRecord)
          );
        });
      },
      $q.when(true)
    );
  }

  function storeFieldRecordData(studyId, fieldRecords, extraTables) {
    return DatabaseProvider.getDatabase().then((database) => {
      return deleteFieldRecordMedia(database, studyId)
        .then(() => deleteFieldRecordExtraTable(database, _.keys(extraTables), studyId))
        .then(() => deleteFieldRecord(database, studyId))
        .then(() => replaceFieldRecordsWithMedia(database, studyId, fieldRecords))
        .then(() => replaceExtraTables(database, extraTables));
    });
  }

  function deleteBiotopeRef(database, tableName) {
    return StorageService.executeSqlQuery(database, `DELETE FROM biotope_ref_${tableName}`);
  }
  function replaceBiotopeRef(database, refs, tableName) {
    return $q.all(
      _.map(refs, (ref) => {
        return StorageService.executeSqlQuery(
          database,
          `INSERT INTO biotope_ref_${tableName} VALUES (?, ?, ?, ?, ?, ?, ?)`,
          [ref.id, ref.key, ref.label, ref.description, ref.level, ref.header, ref.deleted]
        );
      })
    );
  }

  function storeBiotopeRefs(data) {
    return DatabaseProvider.getDatabase().then((database) => {
      return _.reduce(
        _.toPairs(data),
        (previousPromise, [tableName, refs]) => {
          return deleteBiotopeRef(database, tableName).then(() => replaceBiotopeRef(database, refs, tableName));
        },
        $q.when(true)
      );
    });
  }

  function removeLocalTxRef(txRefType) {
    return DatabaseProvider.getDatabase()
      .then((database) => {
        return StorageService.executeSqlQuery(database, 'DELETE FROM tx_ref_import WHERE key = ?', [txRefType]);
      })
      .then(() => DatabaseProvider.deleteReferentielDatabase(txRefType));
  }

  // Fonctions d'upload des données de l'étude
  function sendStudyData(studyId) {
    return StudyRepository.getStudy(studyId).then((study) => {
      return $q
        .all([
          // Récupération des relevés
          loadFieldSurvey(study),
          // Récupération des observations à la volée
          loadFieldRecordOnTheFly(study),
        ])
        .then(([fieldSurveyWithFieldRecords, fieldRecordOnTheFly]) =>
          doSendStudyData(studyId, fieldSurveyWithFieldRecords, fieldRecordOnTheFly)
        );
    });
  }

  function loadFieldSurvey(study) {
    return FieldSurveyRepository.getForStudy(study.id, true, true).then((fieldSurveys) => {
      // Récupération des observations de chaque relevé
      return $q
        .all(
          _.map(fieldSurveys, (fieldSurvey) => {
            return loadFieldRecordForFieldSurvey(fieldSurvey.id).then((fieldRecords) => {
              // Fusion des données du relevé et de ses observations
              return _.defaults(fieldRecords, { fieldSurvey });
            });
          })
        )
        .then((fieldSurveyWithFieldRecords) => {
          const timeToCompare = study.latestSyncTime || study.lockTime;
          fieldSurveyWithFieldRecords = fieldSurveyWithFieldRecords
            .map((fieldSurveyWithFieldRecord) => {
              let defaultValues = {
                // On ne garde que l'id du relevé-type
                surveyType: fieldSurveyWithFieldRecord.fieldSurvey.surveyType.id,
                // On ne garde que l'id des media
                // Le média vient d'être envoyé au serveur donc l'id est "-{idCotéServeur}"
                medias: _.map(fieldSurveyWithFieldRecord.fieldSurvey.medias, (media) =>
                  StorageService.prepareId(media.id)
                ),
              };
              return {
                fieldRecords: fieldSurveyWithFieldRecord.fieldRecords,
                fieldSurvey: _.defaults(defaultValues, fieldSurveyWithFieldRecord.fieldSurvey),
              };
            })
            .filter((fieldSurveyWithFieldRecord) => {
              const { fieldSurvey, fieldRecords } = fieldSurveyWithFieldRecord;
              if (fieldSurvey.updated && timeToCompare && fieldSurvey.updated <= timeToCompare) {
                fieldSurveyWithFieldRecord.withSmallFieldSurvey = true;
                fieldSurveyWithFieldRecord.fieldSurvey = _.pick(fieldSurvey, [
                  'id',
                  'key',
                  'name',
                  'surveyType',
                  'startTime',
                  'protocol',
                ]);
              }
              fieldSurveyWithFieldRecord.fieldRecords = (fieldRecords || []).filter((fieldRecord) => {
                return !fieldRecord.updated || !timeToCompare || fieldRecord.updated > timeToCompare;
              });
              return (
                !fieldSurveyWithFieldRecord.withSmallFieldSurvey ||
                _.get(fieldSurveyWithFieldRecord, 'fieldRecords', []).length
              );
            });

          return {
            fieldSurveys: fieldSurveyWithFieldRecords,
          };
        });
    });
  }

  function loadFieldRecordForFieldSurvey(fieldSurveyId) {
    return FieldRecordRepository.getForFieldSurvey(fieldSurveyId, true).then((fieldRecords) => {
      fieldRecords = _.map(fieldRecords, (fieldRecord) => {
        let defaultValues = {
          // Renommage isTx… -> tx…
          txSure: fieldRecord.isTxSure,
          txPresent: fieldRecord.isTxPresent,
          // On ne garde que l'id des media
          // Le média vient d'être envoyé au serveur donc l'id est "-{idCotéServeur}"
          medias: _.map(fieldRecord.medias, (media) => StorageService.prepareId(media.id)),
        };
        return _.defaults(defaultValues, fieldRecord);
      });
      return {
        fieldRecords: _.filter(fieldRecords, (fieldRecord) => {
          return !fieldRecord.ghost;
        }),
      };
    });
  }

  function loadFieldRecordOnTheFly(study) {
    const timeToCompare = study.latestSyncTime || study.lockTime;
    return FieldRecordRepository.getForStudy(study.id, true, true).then((fieldRecords) => {
      return {
        fieldRecordsOnTheFly: fieldRecords
          .map((fieldRecord) => {
            let defaultValues = {
              // Renommage isTx… -> tx…
              txSure: fieldRecord.isTxSure,
              txPresent: fieldRecord.isTxPresent,
              // On ne garde que l'id des media
              // Le média vient d'être envoyé au serveur donc l'id est "-{idCotéServeur}"
              medias: _.map(fieldRecord.medias, (media) => StorageService.prepareId(media.id)),
            };
            return _.defaults(defaultValues, fieldRecord);
          })
          .filter((fieldRecord) => {
            return !fieldRecord.updated || !timeToCompare || fieldRecord.updated > timeToCompare;
          }),
      };
    });
  }

  function doSendStudyData(studyId, fieldSurveyWithFieldRecords, fieldRecordOnTheFly) {
    return new Promise((resolve, reject) => {
      Geolocation.getTracking().then((tracking) => {
        const trackedSurveys = tracking.surveys;
        if (fieldSurveyWithFieldRecords && fieldSurveyWithFieldRecords.fieldSurveys) {
          _.forEach(fieldSurveyWithFieldRecords.fieldSurveys, (item) => {
            if (item.withSmallFieldSurvey) {
              delete item.withSmallFieldSurvey;
              return;
            }
            const trackedSurvey = _.find(trackedSurveys, (survey) => {
              return survey.id == item.fieldSurvey.id;
            });
            if (trackedSurvey && trackedSurvey.points && trackedSurvey.points.length) {
              const coords = _.map(trackedSurvey.points, (point) => {
                return [point.longitude, point.latitude];
              });
              const exists = item.fieldSurvey.geometry;
              if (_.get(exists, 'type') == 'LineString' && _.get(exists.coordinates, 'length', 0) > 0) {
                item.fieldSurvey.geometry.coordinates = item.fieldSurvey.geometry.coordinates.concat(coords);
              } else {
                item.fieldSurvey.geometry = {
                  type: 'LineString',
                  coordinates: coords,
                };
              }
            }
          });
        }

        const addExtraData = () => {
          return new Promise((resolve, reject) => {
            if (!_.get(fieldSurveyWithFieldRecords, 'fieldSurveys')) {
              return resolve();
            }

            DatabaseProvider.getDatabase().then((database) => {
              const promises = [];
              const groupedRecords = [];
              fieldSurveyWithFieldRecords.fieldSurveys.forEach((item) => {
                item.fieldRecords.forEach((fieldRecord) => {
                  /* promises.push(
                    FieldRecordRepository.loadFieldRecordExtraDatas(database, fieldRecord).then((res) => {
                      fieldRecord.extraTables = res.extraTables;
                    })
                  ); */
                  let group = groupedRecords.find((gp) => {
                    return gp.protocol == item.fieldSurvey.protocol && gp.txGroup == fieldRecord.txGroup;
                  });
                  if (!group) {
                    group = {
                      protocol: item.fieldSurvey.protocol,
                      txGroup: fieldRecord.txGroup,
                      records: [],
                    };
                    groupedRecords.push(group);
                  }
                  group.records.push(fieldRecord);
                });
              });
              groupedRecords.forEach((group) => {
                promises.push(
                  FieldRecordRepository.loadExtraTables(
                    database,
                    group.records.map((record) => record.id),
                    group.txGroup,
                    null,
                    group.protocol
                  ).then((extraTables) => {
                    extraTables.forEach((extraTable) => {
                      const name = Object.keys(extraTable)[0];
                      const fieldRecord = group.records.find((fieldRecord) => {
                        return fieldRecord.id == extraTable[name].id;
                      });
                      if (fieldRecord) {
                        _.set(fieldRecord, `extraTables.${name}`, extraTable[name]);
                      }
                    });
                  })
                );
              });

              $q.all(promises).then(() => {
                resolve();
              });
            });
          });
        };

        addExtraData().then(() => {
          fieldSurveyWithFieldRecords.fieldSurveys.forEach((item) => {
            delete item.fieldSurvey.protocol;
          });
          $http({
            method: 'POST',
            url: SynchroConfiguration.importStudyEndpoint,
            pathParams: { studyId },
            data: _.merge({}, fieldSurveyWithFieldRecords, fieldRecordOnTheFly),
          }).then(
            (response) => {
              resolve(response.data);
            },
            (err) => {
              reject(err);
            }
          );
        });
      });
    });
  }
  function updateStudyLockInfo(studyId, latestSyncTime) {
    return DatabaseProvider.getDatabase().then((database) => {
      return StorageService.executeSqlQuery(
        database,
        'UPDATE study_lock_info SET latest_sync_time = ? WHERE study = ?',
        [latestSyncTime, studyId]
      );
    });
  }

  // Fonctions d'upload de media
  function sendMedia(studyId) {
    return DatabaseProvider.getDatabase().then((database) =>
      getMedia(database, studyId)
        .then((medias) => $q.all(_.map(medias, (media) => doSendMedia(media))))
        .then((mediasIdMapping) => updateMediasId(database, mediasIdMapping))
    );
  }
  function doSendMedia(media) {
    return $q((resolve, reject) => {
      // On regarde si le fichier est présent
      document.addEventListener('deviceready', function() {
        MediaRepository.checkAndGetMediaLocalPath(media).then(
          (result) => {
            doUploadMedia(media).then(
              (mediasIdMapping) => resolve(mediasIdMapping),
              (reason) => reject(reason)
            );
          },
          (reason) => {
            doUpdateMediaMeta(media).then(
              (mediasIdMapping) => resolve(mediasIdMapping),
              (reason) => reject(reason)
            );
          }
        );
      });
    });
  }

  function doUpdateMediaMeta(media) {
    return $http({
      method: 'POST',
      url: SynchroConfiguration.syncMedia,
      data: _.omit(media, _.isNull),
    }).then((response) => response.data);
  }

  function doUploadMedia(media) {
    return $q((resolve, reject) => {
      UrlResolverService.resolveUrl(SynchroConfiguration.syncMedia).then((url) => {
        document.addEventListener(
          'deviceready',
          function() {
            var filePath = MediaRepository.getLocalPath(media);
            $cordovaFileTransfer
              .upload(
                url,
                filePath,
                {
                  headers: { Authorization: 'Bearer ' + AuthStore.getState().token },
                  fileName: media.filename,
                  httpMethod: 'POST',
                  mimeType: media.mimeType,
                  params: _.omit(media, _.isNull),
                },
                false
              )
              .then(
                (result) => resolve(JSON.parse(result.response)),
                (err) => reject(err)
              );
          },
          false
        );
      });
    });
  }
  function updateMediasId(database, mediasIdMapping) {
    if (!mediasIdMapping || !mediasIdMapping.length) {
      return $q.when(true);
    }
    return $q.all(
      _.chain(mediasIdMapping)
        .filter((mediaIdMapping) => mediaIdMapping.oldId !== StorageService.prepareId(mediaIdMapping.newId))
        .map((mediaIdMapping) => {
          let preparedId = StorageService.prepareId(mediaIdMapping.newId);
          return $q.all(
            [
              $cordovaFile.moveFile(
                cordova.file.externalDataDirectory,
                `${mediaIdMapping.oldId}.${mediaIdMapping.filename}`,
                cordova.file.externalDataDirectory,
                `${preparedId}.${mediaIdMapping.filename}`
              ),
            ].concat(
              StorageService.executeSqlQueries(database, [
                {
                  sql: 'UPDATE media SET id = ? WHERE id = ?',
                  parameters: [preparedId, mediaIdMapping.oldId],
                },
                {
                  sql: 'UPDATE field_survey_media SET media = ? WHERE media = ?',
                  parameters: [preparedId, mediaIdMapping.oldId],
                },
                {
                  sql: 'UPDATE field_record_media SET media = ? WHERE media = ?',
                  parameters: [preparedId, mediaIdMapping.oldId],
                },
              ])
            )
          );
        })
        .value()
    );
  }

  function getMedia(database, studyId) {
    return $q
      .all([
        StorageService.executeSqlQuery(
          database,
          `SELECT
          m.id AS id,
          m.mime_type AS mimeType,
          m.filename AS filename,
          m.created AS created,
          m.updated AS updated,
          m.deleted AS deleted
        FROM media m
        JOIN field_survey_media fsm ON (m.id = fsm.media)
        JOIN field_survey fs ON (fs.id = fsm.field_survey)
        JOIN survey_type st ON (st.id = fs.survey_type)
        WHERE st.study = ?`,
          [studyId]
        ),
        StorageService.executeSqlQuery(
          database,
          `SELECT
          m.id AS id,
          m.mime_type AS mimeType,
          m.filename AS filename,
          m.created AS created,
          m.updated AS updated,
          m.deleted AS deleted
        FROM media m
        JOIN field_record_media frm ON (m.id = frm.media)
        JOIN field_record fr ON (fr.id = frm.field_record)
        WHERE fr.study = ?`,
          [studyId]
        ),
      ])
      .then(([mediaFieldSurvey, mediaFieldRecord]) => {
        return mediaFieldSurvey.concat(mediaFieldRecord);
      });
  }

  // Fonctions pour la suppression d'une étude
  function removeStudyData(studyId) {
    return DatabaseProvider.getDatabase().then((database) => {
      return $q
        .all([
          removeMedia(database, studyId).then(() =>
            $q.all([
              removeFieldRecord(database, studyId),
              removeFieldSurvey(database, studyId).then(() => removeSurveyType(database, studyId)),
            ])
          ),
          removeStudyLockInfo(database, studyId),
          removeTerrUnit(database, studyId),
          removeStudyExpert(database, studyId),
        ])
        .then(() => removeStudy(database, studyId));
    });
  }
  function removeMedia(database, studyId) {
    return getMedia(database, studyId).then((medias) => {
      return $q.all(
        _.map(medias, (media) => {
          return MediaRepository.checkAndGetMediaLocalPath(media).then(
            (result) => {
              $cordovaFile.removeFile(cordova.file.externalDataDirectory, `${media.id}.${media.filename}`);
            },
            (reason) => {
              // Le fichier n'existe pas, ce n'est pas bloquant
              return;
            }
          );
        })
      );
    });
  }
  function removeStudy(database, studyId) {
    return StorageService.executeSqlQuery(database, 'DELETE FROM study WHERE id = ?', [studyId]);
  }
  function removeStudyLockInfo(database, studyId) {
    return StorageService.executeSqlQuery(database, 'DELETE FROM study_lock_info WHERE study = ?', [studyId]);
  }
  function removeStudyExpert(database, studyId) {
    return StorageService.executeSqlQuery(database, 'DELETE FROM study_expert WHERE study = ?', [studyId]);
  }
  function removeTerrUnit(database, studyId) {
    return StorageService.executeSqlQuery(database, 'DELETE FROM terr_unit WHERE study = ?', [studyId]);
  }
  function removeFieldSurvey(database, studyId) {
    var extraTables = _.chain(FS_EXTRA_TABLE.VALUES)
      .map((extraTable) => fieldSurveyExtraTableUtil.toTableName(extraTable))
      .uniq()
      .value();
    return deleteFieldSurveyMedia(database, studyId)
      .then(() => deleteFieldSurveyExtraTable(database, extraTables, studyId))
      .then(() => deleteFieldSurvey(database, studyId));
  }
  function removeFieldRecord(database, studyId) {
    var extraTables = _.chain(FR_EXTRA_TABLE.VALUES)
      .map((extraTable) => fieldRecordExtraTableUtil.toTableName(extraTable))
      .uniq()
      .value();
    return deleteFieldRecordMedia(database, studyId)
      .then(() => deleteFieldRecordExtraTable(database, extraTables, studyId))
      .then(() => deleteFieldRecord(database, studyId));
  }
  function removeSurveyType(database, studyId) {
    return deleteSurveyTypeProtocolTxGroup(database, studyId).then(() => deleteSurveyType(database, studyId));
  }
  function removeStudyMap(studyId, imagerySets) {
    var promises = [DatabaseProvider.getDatabase().then((database) => removeAllLayers(database, studyId))].concat(
      _.map(imagerySets, (imagerySet) => DatabaseProvider.deleteMbTilesDatabase(imagerySet, studyId))
    );
    return $q.all(promises);
  }
}
