import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as TAXON_SEARCH_BY_TXGROUPS_STORE_EVENTS } from '../../stores/taxon-search-by-txgroups.store';
import { EVENTS as TAXON_DETAILS_STORE_EVENTS } from '../../stores/taxon-details.store';

require('./select-taxon.scss');

export default SelectTaxonDirective;

// @ngInject
function SelectTaxonDirective() {
  return {
    restrict: 'E',
    scope: {
      studyId: '@',
      initialTxKey: '@initialTxKey',
      txRefKey: '@',
      txRefVersion: '@',
      txRefFilter: '@',
      txGroupsProvider: '&txGroups',
      placeholder: '@',
      onChangeFn: '&onChange',
      floatingLabel: '@',
      name: '@',
      requiredProvider: '&required',
      disabledProvider: '&disabled',
      showStatusMode: '@',
    },
    bindToController: true,
    controller: SelectTaxonController,
    controllerAs: 'selectTaxonCtrl',
    template: require('./select-taxon.html'),
    replace: true,
  };
}

// @ngInject
function SelectTaxonController(
  $rootScope,
  $scope,
  TaxonSearchByTxGroupStore,
  TaxonDetailsStore,
  TaxonSearchByTxGroupService,
  TaxonDetailsService,
  LxDialogService
) {
  var selectTaxonCtrl = this;
  var deleted = false;

  // Dans un cas bien précis (création d'une observation pour un relevé depuis le listing des observations d'un relevé),
  // le controller n'est pas supprimé à temps et pose problème dans l'affichage du formulaire de création (le taxon sélectionné précédemment
  // n'est plus sélectionné)
  // En attendant de trouver un fix plus propre, on peut interdire à ce composant de refiltrer ses données dès que le signal de suppression est lancé
  $scope.$on('$destroy', () => {
    deleted = true;
  });

  selectTaxonCtrl.onChange = onChange;
  selectTaxonCtrl.onFilterChanged = _.debounce(onFilterChanged, 150);
  selectTaxonCtrl.taxon = false;
  selectTaxonCtrl.openStatuses = openStatuses;
  selectTaxonCtrl.taxonDetailsService = TaxonDetailsService;
  selectTaxonCtrl.taxonStatusDisplay = {};

  $scope.$on(TAXON_SEARCH_BY_TXGROUPS_STORE_EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_STATE_CHANGED, reloadResults);
  $scope.$on(TAXON_DETAILS_STORE_EVENTS.TAXON_DETAILS_STATE_CHANGED, reloadDefaultTaxon);

  selectTaxonCtrl.showStatusMode = selectTaxonCtrl.showStatusMode || 'compact';

  $scope.$watch(
    () => selectTaxonCtrl.initialTxKey,
    (initialTxKey) => {
      console.log(initialTxKey);
      if (!initialTxKey) {
        // Pas de valeur initiale, on considère qu'elle est déjà trouvée
        selectTaxonCtrl.initialValueFound = true;
        // On efface si besoin currentValue
        selectTaxonCtrl.currentValue = null;
        return;
      }

      selectTaxonCtrl.initialValueFound = false;
      selectTaxonCtrl.initialTxKey = initialTxKey;

      if (!selectTaxonCtrl.availableValues || selectTaxonCtrl.availableValues.length <= 0) {
        return;
      }

      // Cherchons la valeur par défaut
      selectTaxonCtrl.currentValue = _.find(selectTaxonCtrl.availableValues, { key: initialTxKey });
      // Il est fort probable que la valeur par défaut n'ait pas été trouvée, on retentera la prochaine fois
      if (selectTaxonCtrl.currentValue) {
        selectTaxonCtrl.initialValueFound = true;

      onChange(selectTaxonCtrl.currentValue);
    } else {
          TaxonSearchByTxGroupService.load(selectTaxonCtrl.studyId, selectTaxonCtrl.txRefKey, selectTaxonCtrl.txRefVersion,selectTaxonCtrl.txRefFilter,
              _.filter(selectTaxonCtrl.txGroups), '');
      }
    }
  );
  $scope.$watch(
    () => selectTaxonCtrl.txRefKey,
    () => selectTaxonCtrl.onFilterChanged(selectTaxonCtrl.oldFilter)
  );
  $scope.$watch(
    () => selectTaxonCtrl.txRefVersion,
    () => selectTaxonCtrl.onFilterChanged(selectTaxonCtrl.oldFilter)
  );
  $scope.$watch(() => selectTaxonCtrl.txRefFilter, () => selectTaxonCtrl.onFilterChanged(selectTaxonCtrl.oldFilter));
  $scope.$watch(selectTaxonCtrl.txGroupsProvider, (txGroups) => {
    selectTaxonCtrl.txGroups = txGroups;
    selectTaxonCtrl.onFilterChanged(selectTaxonCtrl.oldFilter);
  });

  selectTaxonCtrl.availableValues = [];
  selectTaxonCtrl.state = {};

  $scope.$watch(selectTaxonCtrl.requiredProvider, (required) => {
    selectTaxonCtrl.required = required;
  });
  $scope.$watch(selectTaxonCtrl.disabledProvider, (disabled) => {
    selectTaxonCtrl.disabled = disabled;
  });

  function reloadResults() {
    selectTaxonCtrl.state = TaxonSearchByTxGroupStore.getState();
    if (!selectTaxonCtrl.state.results) {
      return;
    }

    processState();
  }

  function reloadDefaultTaxon() {
    var taxonDetailsState = TaxonDetailsStore.getState();
    selectTaxonCtrl.state = _.defaults(
      { results: [taxonDetailsState.taxon] },
      _.pick(taxonDetailsState, 'loading', 'loaded', 'error')
    );

    processState();
  }

  function processState() {
    selectTaxonCtrl.availableValues = selectTaxonCtrl.state.results;
    if (selectTaxonCtrl.initialValueFound && !selectTaxonCtrl.currentValue) {
      return;
    }

    if (!selectTaxonCtrl.initialValueFound) {
      // La valeur initiale n'a pas encore été trouvée, on cherche dans les données reçues si la valeur initiale est disponible
      selectTaxonCtrl.currentValue = _.find(selectTaxonCtrl.availableValues, { key: selectTaxonCtrl.initialTxKey });

      selectTaxonCtrl.initialValueFound = !!selectTaxonCtrl.currentValue;
    } else {
      // La valeur initiale a déjà été trouvée.
      // On se trouve donc dans le cas où le filtre de recherche (= groupe taxonomique + référentiel + filtre textuel) a été modifié
      // On tente de retrouver l'ancienne valeur dans les résultats obtenus
      var found = _.find(selectTaxonCtrl.availableValues, { key: selectTaxonCtrl.currentValue.key });
      // On ne force pas l'effacement de la valeur dans certains cas si on ne trouve pas l'ancienne valeur
      // sinon ca entraîne du rechargement non voulu
      if (found) {
        selectTaxonCtrl.currentValue = found;
      }
    }

    onChange(selectTaxonCtrl.currentValue);
  }

  function onFilterChanged(filter) {
    if (deleted) {
      return;
    }
    selectTaxonCtrl.oldFilter = filter;
    if (!selectTaxonCtrl.txRefKey || !selectTaxonCtrl.txRefVersion || !selectTaxonCtrl.txRefFilter) {
      selectTaxonCtrl.currentValue = null;
      return;
    }

    // Si initialTxKey != null et que initialValueFound est faux, cela signifie que la valeur initiale n'a pas encore été trouvée.
    // Cette valeur initiale n'est intéressante qu'au tout début et n'est plus à utiliser quand elle a déjà été retrouvée.
    if (selectTaxonCtrl.initialTxKey && !selectTaxonCtrl.initialValueFound) {
      TaxonDetailsService.load(selectTaxonCtrl.txRefKey, selectTaxonCtrl.txRefVersion,selectTaxonCtrl.txRefFilter,selectTaxonCtrl.initialTxKey);
      return;
    }

      TaxonSearchByTxGroupService.load(selectTaxonCtrl.studyId, selectTaxonCtrl.txRefKey, selectTaxonCtrl.txRefVersion,selectTaxonCtrl.txRefFilter,
        _.filter(selectTaxonCtrl.txGroups), filter);
  }

  function onChange(newValue) {
    selectTaxonCtrl.taxon = newValue;
    selectTaxonCtrl.taxonStatusDisplay = {
      extended: _.get(selectTaxonCtrl.taxon, 'head_statuses.extended', []).map((status) => {
        return {
          ...status,
          value: {
            short: status.value,
            long: TaxonDetailsService.getStatusDisplay('value', status.value).long,
          },
          scale: TaxonDetailsService.getStatusDisplay('scale', status.scale),
        };
      }),
      simplified: _.get(selectTaxonCtrl.taxon, 'head_statuses.simplified', []).map((status) => {
        return {
          ...status,
          value: {
            short: status.value,
            long: TaxonDetailsService.getStatusDisplay('value', status.value).long,
          },
        };
      }),
    };
    selectTaxonCtrl.onChangeFn() && selectTaxonCtrl.onChangeFn()(newValue);
  }

  function openStatuses() {
    LxDialogService.open('dialog-taxon-statuses');
  }
}
