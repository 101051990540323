import _ from 'lodash';

import { EVENTS as STUDY_GEOM_STORE_EVENTS } from '../../../study/stores/study-map.store';
import { EVENTS as FIELD_SURVEY_STORE_EVENTS } from '../../stores/field-survey-details.store';
import { EVENTS as FIELD_RECORD_LIST_GEOM_STORE_EVENTS } from '../../../field-record/stores/field-record-list-map.store';
import { EVENTS as FIELD_RECORD_DETAILS_STORE_EVENTS } from '../../../field-record/stores/field-record-details.store';
import { LAYERS_CONFIG } from '../../../map/configuration';
import { geoJsonFromLatlng } from '../../../map/tools/map-util.js';

require('./field-survey-details-map-tab.scss');

export default FieldSurveyDetailsMapTabDirective;

// @ngInject
function FieldSurveyDetailsMapTabDirective() {
  return {
    restrict: 'E',
    scope: {
      fieldSurveyId: '@',
      studyId: '@',
      fieldSurveyStateProvider: '&fieldSurveyState',
      fieldSurveyGeomProvider: '&fieldSurveyGeom',
      layersStateProvider: '&layersState',
      onLocalLayerChangedFn: '&onLocalLayerChanged',
      canEditFn: '&canEdit',
      onPositionChangeFn: '&onPositionChange',
    },
    bindToController: true,
    controller: FieldSurveyDetailsMapTabController,
    controllerAs: 'fieldSurveyDetailsMapTabCtrl',
    template: require('./field-survey-details-map-tab.html'),
    replace: true,
  };
}

// @ngInject
function FieldSurveyDetailsMapTabController(
  $scope,
  $compile,
  $rootScope,
  StudyMapStore,
  StudyMapService,
  FieldSurveyMapStore,
  FieldSurveyMapService,
  FieldRecordListMapStore,
  FieldRecordListMapService,
  Geolocation,
  LxNotificationService,
  StudyExpertLayerService
) {
  var fieldSurveyDetailsMapTabCtrl = this;

  fieldSurveyDetailsMapTabCtrl.layers = [];
  fieldSurveyDetailsMapTabCtrl.loaded = false;
  fieldSurveyDetailsMapTabCtrl.noGeoms = true;
  fieldSurveyDetailsMapTabCtrl.geomEditors = [];
  fieldSurveyDetailsMapTabCtrl.viewZoom = 16;
  fieldSurveyDetailsMapTabCtrl.gpsLoading = false;

  fieldSurveyDetailsMapTabCtrl.onLayerChanged = StudyMapService.saveCurrentLayer;
  fieldSurveyDetailsMapTabCtrl.onLocalLayerChanged = onLocalLayerChanged;
  fieldSurveyDetailsMapTabCtrl.canEdit = canEdit;
  fieldSurveyDetailsMapTabCtrl.isGeomEditable = isGeomEditable;
  fieldSurveyDetailsMapTabCtrl.isGeomEditorEnabled = isGeomEditorEnabled;
  fieldSurveyDetailsMapTabCtrl.startEditing = startEditing;
  fieldSurveyDetailsMapTabCtrl.onValidateEditing = onValidateEditing;
  fieldSurveyDetailsMapTabCtrl.onCancelEditing = onCancelEditing;
  fieldSurveyDetailsMapTabCtrl.onMove = onMove;
  fieldSurveyDetailsMapTabCtrl.setPositionFromGPS = setPositionFromGPS;
  fieldSurveyDetailsMapTabCtrl.startDraw = startDraw;
  fieldSurveyDetailsMapTabCtrl.onDrawEdited = onDrawEdited;
  fieldSurveyDetailsMapTabCtrl.onDrawDeleted = onDrawDeleted;
  fieldSurveyDetailsMapTabCtrl.initialPosition = null;

  fieldSurveyDetailsMapTabCtrl.tracking = {};
  fieldSurveyDetailsMapTabCtrl.trackingState = null;
  fieldSurveyDetailsMapTabCtrl.trackingCtrl = null;
  fieldSurveyDetailsMapTabCtrl.onMapReady = onMapReady;
  let nbReload = 0;
  let nbUpdateLayers = 0;

  $scope.$watch(fieldSurveyDetailsMapTabCtrl.fieldSurveyStateProvider, onFieldSurveyStateChanged);
  $scope.$watch(fieldSurveyDetailsMapTabCtrl.fieldSurveyGeomProvider, onFieldSurveyGeomChanged);
  $scope.$watch(fieldSurveyDetailsMapTabCtrl.layersStateProvider, onLayersStateChanged);

  $scope.$on(STUDY_GEOM_STORE_EVENTS.STUDY_MAP_STATE_CHANGED, onMapStudyStateChanged);
  $scope.$on(FIELD_RECORD_LIST_GEOM_STORE_EVENTS.FIELD_RECORD_LIST_MAP_STATE_CHANGED, onMapFieldRecordListStateChanged);
  $scope.$on(FIELD_SURVEY_STORE_EVENTS.FIELD_SURVEY_DETAILS_STATE_CHANGED, reload);
  $scope.$on(FIELD_RECORD_DETAILS_STORE_EVENTS.FIELD_RECORD_DETAILS_STATE_CHANGED, reload);

  var drawGeom = null;

  fieldSurveyDetailsMapTabCtrl.mapCenterMarker = LAYERS_CONFIG.FIELD_SURVEY_DETAIL;

  function canEdit() {
    return fieldSurveyDetailsMapTabCtrl.canEditFn() && fieldSurveyDetailsMapTabCtrl.canEditFn()();
  }

  function isGeomEditable() {
    return fieldSurveyDetailsMapTabCtrl.geomEditors.length;
  }

  function isGeomEditorEnabled(editorName) {
    return fieldSurveyDetailsMapTabCtrl.geomEditors.indexOf(editorName) > -1;
  }

  function onLocalLayerChanged(layerId) {
    fieldSurveyDetailsMapTabCtrl.onLocalLayerChangedFn() &&
      fieldSurveyDetailsMapTabCtrl.onLocalLayerChangedFn()(layerId);
  }

  function onMapStudyStateChanged() {
    fieldSurveyDetailsMapTabCtrl.studyGeomState = StudyMapStore.getState();
    updateLayers();
  }

  function onMapFieldRecordListStateChanged() {
    fieldSurveyDetailsMapTabCtrl.fieldRecordListGeomState = FieldRecordListMapStore.getState();
    updateLayers();
  }
  function onFieldSurveyGeomChanged(fieldSurveyGeom) {
    fieldSurveyDetailsMapTabCtrl.currentGeometry = fieldSurveyGeom;
    updateLayers();
  }
  function onLayersStateChanged(layersState) {
    fieldSurveyDetailsMapTabCtrl.layersState = layersState;
    updateLayers();
  }
  function onFieldSurveyStateChanged(fieldSurveyState) {
    fieldSurveyDetailsMapTabCtrl.fieldSurveyState = fieldSurveyState;
    const protocol = _.get(fieldSurveyState, 'fieldSurvey.surveyType.protocol');
    const protocoleKey = _.get(protocol, 'key');

    if (protocoleKey != 'RI') {
      var geomType = protocol.fieldSurveyGeometryType;
      if (['POINT', 'ALL_SIMPLE'].indexOf(geomType) > -1) {
        fieldSurveyDetailsMapTabCtrl.geomEditors.push('point');
      }
      if (['LINESTRING', 'ALL_SIMPLE'].indexOf(geomType) > -1) {
        fieldSurveyDetailsMapTabCtrl.geomEditors.push('draw');
      }
      reload();
      updateLayers();
    } else {
      fieldSurveyDetailsMapTabCtrl.initialPosition = StudyMapService.getCurrentPositionAsGeoJson(
        fieldSurveyDetailsMapTabCtrl.studyId
      );
      reload();
    }
  }

  function updateLayers() {
    if (
      !fieldSurveyDetailsMapTabCtrl.studyGeomState ||
      !fieldSurveyDetailsMapTabCtrl.studyGeomState.loaded ||
      (fieldSurveyDetailsMapTabCtrl.fieldSurveyId &&
        (!fieldSurveyDetailsMapTabCtrl.fieldRecordListGeomState ||
          !fieldSurveyDetailsMapTabCtrl.fieldRecordListGeomState.loaded)) ||
      !fieldSurveyDetailsMapTabCtrl.fieldSurveyState ||
      !fieldSurveyDetailsMapTabCtrl.fieldSurveyState.loaded ||
      !fieldSurveyDetailsMapTabCtrl.layersState ||
      !fieldSurveyDetailsMapTabCtrl.layersState.loaded ||
      fieldSurveyDetailsMapTabCtrl.trackingState !== 'loaded'
    ) {
      return;
    }
    if (_.get(fieldSurveyDetailsMapTabCtrl.fieldSurveyState, 'fieldSurvey.surveyType.protocol.key') == 'RI') {
      ++nbUpdateLayers;
    }
    var newLayers = [];

    if (fieldSurveyDetailsMapTabCtrl.layersState.loaded && fieldSurveyDetailsMapTabCtrl.layersState.layers) {
      newLayers = newLayers.concat(
        StudyExpertLayerService.buildLayerList(
          fieldSurveyDetailsMapTabCtrl.layersState,
          fieldSurveyDetailsMapTabCtrl.studyGeomState.study.id
        )
      );
    }
    if (fieldSurveyDetailsMapTabCtrl.studyGeomState.study.studyBoundary) {
      newLayers.push({
        name: "Aire d'étude",
        config: LAYERS_CONFIG.STUDY,
        fitBounds:
          nbUpdateLayers < 2 &&
          (!fieldSurveyDetailsMapTabCtrl.isEditing || !fieldSurveyDetailsMapTabCtrl.currentGeometry),
        features: [
          _.defaults(
            {
              properties: { name: fieldSurveyDetailsMapTabCtrl.studyGeomState.study.key },
            },
            fieldSurveyDetailsMapTabCtrl.studyGeomState.study.studyBoundary
          ),
        ],
      });
      fieldSurveyDetailsMapTabCtrl.noGeoms = false;
    }

    // On ajoute le layer geometry si on n'est pas en mode édition
    if (
      fieldSurveyDetailsMapTabCtrl.currentGeometry &&
      (!fieldSurveyDetailsMapTabCtrl.isEditing || fieldSurveyDetailsMapTabCtrl.isEditing == 'draw')
    ) {
      newLayers.push({
        name: 'Relevé',
        config: {
          drawnable: true,
          ...LAYERS_CONFIG.FIELD_SURVEY,
        },
        fitBounds: nbUpdateLayers < 2,
        features: [
          _.defaults(
            {
              properties: {
                studyId: fieldSurveyDetailsMapTabCtrl.studyId,
                fieldSurveyId: fieldSurveyDetailsMapTabCtrl.fieldSurveyState.fieldSurvey.id,
                name: fieldSurveyDetailsMapTabCtrl.fieldSurveyState.fieldSurvey.name,
              },
            },
            fieldSurveyDetailsMapTabCtrl.currentGeometry
          ),
        ],
      });
      newLayers.push({
        name: 'Étiquettes relevés',
        config: LAYERS_CONFIG.FIELD_SURVEY_POPUP,
      });
      fieldSurveyDetailsMapTabCtrl.noGeoms = false;
    }

    if (fieldSurveyDetailsMapTabCtrl.tracking.surveys && fieldSurveyDetailsMapTabCtrl.tracking.surveys.length) {
      fieldSurveyDetailsMapTabCtrl.trackingCtrl = new LAYERS_CONFIG.TRACKING(
        $rootScope,
        $compile,
        Geolocation,
        LxNotificationService,
        fieldSurveyDetailsMapTabCtrl.studyId
      );
      newLayers.push({
        name: 'Traces',
        config: fieldSurveyDetailsMapTabCtrl.trackingCtrl.getConf(),
        fitBounds: nbUpdateLayers < 2,
        isTrackingLayer: true,
        features: fieldSurveyDetailsMapTabCtrl.tracking.surveys.map((trackingSurvey, i) => {
          return {
            properties: {
              name: `Trace ${trackingSurvey.name}`,
              index: i,
              trackingSurvey,
            },
            type: 'LineString',
            coordinates: _.map(trackingSurvey.points, (point) => {
              return [point.longitude, point.latitude];
            }),
          };
        }),
      });
      newLayers.push({
        name: 'Étiquettes des traces',
        config: fieldSurveyDetailsMapTabCtrl.trackingCtrl.getPopupCtrl(),
      });
      fieldSurveyDetailsMapTabCtrl.noGeoms = false;
    }

    if (
      fieldSurveyDetailsMapTabCtrl.fieldRecordListGeomState &&
      fieldSurveyDetailsMapTabCtrl.fieldRecordListGeomState.fieldRecords &&
      fieldSurveyDetailsMapTabCtrl.fieldRecordListGeomState.fieldRecords.length
    ) {
      newLayers.push({
        name: 'Observations',
        config: LAYERS_CONFIG.TX_POSITION,
        fitBounds: nbUpdateLayers < 2 && !fieldSurveyDetailsMapTabCtrl.isEditing,
        features: _.map(fieldSurveyDetailsMapTabCtrl.fieldRecordListGeomState.fieldRecords, (fieldRecord) =>
          _.defaults(
            {
              properties: {
                studyId: fieldSurveyDetailsMapTabCtrl.studyId,
                fieldRecordId: fieldRecord.id,
                name: fieldRecord.txHeadcount
                  ? `${fieldRecord.txName} : ${fieldRecord.txHeadcount}`
                  : fieldRecord.txName,
              },
            },
            fieldRecord.txPosition
          )
        ),
      });
      newLayers.push({
        name: 'Étiquettes observations',
        config: LAYERS_CONFIG.TX_POPUP,
      });
      fieldSurveyDetailsMapTabCtrl.noGeoms = false;
    }
    if (!layersEqual(fieldSurveyDetailsMapTabCtrl.layers, newLayers)) {
      fieldSurveyDetailsMapTabCtrl.layers = newLayers;
    }
    fieldSurveyDetailsMapTabCtrl.loaded = true;
  }

  function layersEqual(v1, v2) {
    if (!v1 || !v2) {
      return false;
    }
    if (v1.length != v2.length) {
      return false;
    }
    for (var i = 0; i < v1.length; i++) {
      // On considère que 2 couches KML/MBTiles de même nom sont identiques
      // (le contenu n'a pas changé si on n'a pas quitté la page)
      if (v1[i].name == v2[i].name && ((v1[i].kml && v2[i].kml) || (v1[i].mbtiles && v2[i].mbtiles))) {
        continue;
      }
      if (!_.isEqual(v1[i], v2[i])) {
        return false;
      }
    }
    return true;
  }
  function reload() {
    if (_.get(fieldSurveyDetailsMapTabCtrl.fieldSurveyState, 'fieldSurvey.surveyType.protocol.key') == 'RI') {
      ++nbReload;
    }
    console.log(nbReload);
    if (nbReload < 2) {
      StudyMapService.load(fieldSurveyDetailsMapTabCtrl.studyId);
    }
    if (fieldSurveyDetailsMapTabCtrl.fieldSurveyId) {
      FieldRecordListMapService.load(fieldSurveyDetailsMapTabCtrl.fieldSurveyId);
    }
    // On recharge la position en relevé itinérant après une sauvegarde
    if (isReleveItinerant() && nbReload < 2) {
      fieldSurveyDetailsMapTabCtrl.initialPosition = StudyMapService.getCurrentPositionAsGeoJson(
        fieldSurveyDetailsMapTabCtrl.studyId
      );
    }

    if (!IS_CORDOVA) {
      fieldSurveyDetailsMapTabCtrl.trackingState = 'loaded';
      updateLayers();
    } else {
      Geolocation.getTracking().then((tracking) => {
        fieldSurveyDetailsMapTabCtrl.tracking = {
          ...tracking,
          surveys: tracking.surveys.filter((survey) => {
            console.log(survey.id === Number(fieldSurveyDetailsMapTabCtrl.fieldSurveyId));
            return survey.id === Number(fieldSurveyDetailsMapTabCtrl.fieldSurveyId);
          }),
        };
        fieldSurveyDetailsMapTabCtrl.trackingState = 'loaded';
        if (nbReload < 2) {
          updateLayers();
        }
      });
    }
  }

  function onPositionChange(geometry, isAutoLocated, precision, goToFirstTab) {
    $scope.fieldSurveyMapForm.$setDirty();

    fieldSurveyDetailsMapTabCtrl.onPositionChangeFn() &&
      fieldSurveyDetailsMapTabCtrl.onPositionChangeFn()(geometry, isAutoLocated, precision, goToFirstTab);
  }

  function setPositionFromGPS() {
    fieldSurveyDetailsMapTabCtrl.gpsLoading = true;
    Geolocation.getCurrentPosition().then(
      (position) => {
        fieldSurveyDetailsMapTabCtrl.gpsLoading = false;
        // Notification du container : on utilise le GPS pour définir les positions
        var gpsPoint = geoJsonFromLatlng(position.coords.latitude, position.coords.longitude);
        onPositionChange(gpsPoint, true, position.coords.accuracy, false);
      },
      () => {
        fieldSurveyDetailsMapTabCtrl.gpsLoading = false;
        onPositionChange(null, true, null, false);
        LxNotificationService.error('Une erreur est survenue lors de la récupération de la position GPS');
      }
    );
  }

  function startEditing() {
    fieldSurveyDetailsMapTabCtrl.previousGeometry = fieldSurveyDetailsMapTabCtrl.currentGeometry;
    // Si on a déjà une géométrie : on passe juste en édition
    if (fieldSurveyDetailsMapTabCtrl.currentGeometry) {
      fieldSurveyDetailsMapTabCtrl.isEditing = 'point';
      updateLayers();
      return;
    }

    // Sinon on initialise à la position du GPS
    fieldSurveyDetailsMapTabCtrl.gpsLoading = true;
    Geolocation.getCurrentPosition()
      .then(
        (position) => {
          fieldSurveyDetailsMapTabCtrl.gpsLoading = false;
          // On n'a pas de valeur de geometry : on initialise à la position du GPS, sinon on garde la valeur initiale
          fieldSurveyDetailsMapTabCtrl.currentGeometry = geoJsonFromLatlng(
            position.coords.latitude,
            position.coords.longitude
          );
          fieldSurveyDetailsMapTabCtrl.isEditing = 'point';
        },
        () => {
          fieldSurveyDetailsMapTabCtrl.gpsLoading = false;
          LxNotificationService.error('Une erreur est survenue lors de la récupération de la position GPS');
          fieldSurveyDetailsMapTabCtrl.isEditing = 'point';
        }
      )
      .then(updateLayers);
  }

  function onValidateEditing() {
    var editor = fieldSurveyDetailsMapTabCtrl.isEditing;
    fieldSurveyDetailsMapTabCtrl.isEditing = false;

    // geometry est définit à partir du centre de la carte
    if (fieldSurveyDetailsMapTabCtrl.currentCenter && editor == 'point') {
      fieldSurveyDetailsMapTabCtrl.currentGeometry = geoJsonFromLatlng(
        fieldSurveyDetailsMapTabCtrl.currentCenter.lat,
        fieldSurveyDetailsMapTabCtrl.currentCenter.lng
      );
    }
    if (editor == 'draw') {
      fieldSurveyDetailsMapTabCtrl.currentGeometry = drawGeom ? drawGeom.features[0].geometry : null;
    }
    // Notification du container
    onPositionChange(fieldSurveyDetailsMapTabCtrl.currentGeometry, false, null, true);
  }

  function onCancelEditing() {
    fieldSurveyDetailsMapTabCtrl.isEditing = false;

    // Réinitialisation à partir de la géométrie actuelle
    fieldSurveyDetailsMapTabCtrl.currentGeometry = fieldSurveyDetailsMapTabCtrl.previousGeometry;

    updateLayers();
  }

  function onMove(center, zoom) {
    // On conserve la nouvelle position en relevé itinérant
    if (isReleveItinerant()) {
      StudyMapService.saveCurrentPosition(fieldSurveyDetailsMapTabCtrl.studyId, center.lat, center.lng, zoom);
    }
    if (fieldSurveyDetailsMapTabCtrl.isEditing == 'point') {
      fieldSurveyDetailsMapTabCtrl.currentCenter = center;
    }
  }

  function isReleveItinerant() {
    return (
      fieldSurveyDetailsMapTabCtrl.fieldSurveyState &&
      fieldSurveyDetailsMapTabCtrl.fieldSurveyState.fieldSurvey &&
      fieldSurveyDetailsMapTabCtrl.fieldSurveyState.fieldSurvey.surveyType &&
      fieldSurveyDetailsMapTabCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol &&
      fieldSurveyDetailsMapTabCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol.key === 'RI'
    );
  }

  function startDraw() {
    fieldSurveyDetailsMapTabCtrl.previousGeometry = fieldSurveyDetailsMapTabCtrl.currentGeometry;

    if (drawGeom) {
      fieldSurveyDetailsMapTabCtrl.currentGeometry = drawGeom;
    }

    fieldSurveyDetailsMapTabCtrl.isEditing = 'draw';
    updateLayers();
  }

  function onDrawEdited(e) {
    console.log(e);
    drawGeom = e;
  }

  function onDrawDeleted() {
    console.log('onDrawDeleted');
    drawGeom = null;
  }

  function onMapReady(mapApi) {
    if (fieldSurveyDetailsMapTabCtrl.trackingCtrl) {
      fieldSurveyDetailsMapTabCtrl.trackingCtrl.onMapReady(mapApi);
    }
  }
}
